import styled from 'styled-components';
import { spacing, colours } from '../../../assets/theme';
import FadeBlock from '../../atoms/Fade';

export const DayList = styled.ul`
  margin-bottom: ${props => (props.isLast ? spacing[8] : spacing[4])}px;
`;

export const DateHeader = styled.div`
  padding: ${spacing[3]}px;
  border-bottom: ${({ isLast, theme }) => (isLast ? 'none' : `1px solid ${theme?.foreground}`)};
`;

export const NoResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
`;

export const Fade = styled(FadeBlock)`
  position: fixed;
  bottom: 52px;
`;
