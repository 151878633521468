import styled from 'styled-components';
import { Row } from '../../Layout/Flex';
import { spacing, fontFamilies, textSizes } from '../../../assets/theme';

export const PaddedRow = styled(Row)`
  margin: ${spacing[5]}px ${spacing[2]}px ${spacing[3]}px ${spacing[2]}px;
`;

export const SearchInput = styled.input`
  background-color: ${({ theme }) => theme?.background};
  color: ${({ theme }) => theme?.foreground};
  padding: ${spacing[4] / 2}px 0;
  border: none;
  font-family: ${fontFamilies[0]};
  font-size: ${textSizes.lg}px;
  border-bottom: 1px solid ${({ theme }) => theme?.background};
  text-align: right;
  width: ${({ valueLength }) => valueLength}ch;
  min-width: 6ch;
  position: relative;
  font-weight: 500;
  top: 3px;

  :focus {
    outline: none;
  }
`;
