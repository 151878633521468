import React from 'react';
import { number, string } from 'prop-types';

const Search = ({ size = '24', colour = 'inherit', ...rest }) => (
  <svg viewBox="0 0 24 24" height={size} width={size} {...rest}>
    <path
      fillRule="evenodd"
      fill={colour}
      clipRule="evenodd"
      d="M24.7376 21.646L18.5326 15.441C19.6996 13.836 20.3896 11.862 20.3896 9.73C20.3896 4.365 16.0246 0 10.6586 0C5.29359 0 0.928589 4.365 0.928589 9.73C0.928589 15.096 5.29359 19.46 10.6586 19.46C12.6926 19.46 14.5816 18.833 16.1456 17.762L22.3836 24L24.7376 21.646V21.646ZM3.78457 9.73C3.78457 5.938 6.86957 2.853 10.6616 2.853C14.4536 2.853 17.5386 5.938 17.5386 9.73C17.5386 13.522 14.4536 16.607 10.6616 16.607C6.86857 16.607 3.78457 13.522 3.78457 9.73Z"
    />
  </svg>
);

Search.propTypes = {
  size: number,
  colour: string,
};

export default Search;
