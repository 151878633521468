import { isAfter, format, isToday, isTomorrow } from 'date-fns';
import { fr, enGB } from 'date-fns/locale';
import { capitaliseFirstLetter, sanitize } from '../../../helpers/string';

export const getArchiveFromShowData = (data, searchQuery) => {
  const filteredList = data?.allStrapiUpcomingShow.nodes
    ?.filter(item => {
      const now = new Date();
      const showTime = new Date(item.broadcast_start_time);
      const titleHostsString = `${sanitize(item.title)}`;
      const inSearchResult = sanitize(titleHostsString).includes(sanitize(searchQuery));
      if (searchQuery) {
        return inSearchResult && isAfter(now, showTime) && item.active && item.mixcloud_slug;
      }
      return isAfter(now, showTime) && item.active && item.mixcloud_slug;
    })
    .reduce((obj, item) => {
      const newObject = obj;
      const { broadcast_start_time: broadcastStartTime } = item;
      const startTime = format(new Date(broadcastStartTime), 'yyyy-MM-dd');

      if (newObject[startTime]) {
        newObject[startTime] = [...newObject[startTime], item];
      } else {
        newObject[startTime] = [item];
      }

      return newObject;
    }, {});

  return filteredList;
};

export const formatDay = (dateTime, lang) => {
  const locale = lang === 'fr' ? fr : enGB;

  const date = new Date(dateTime);
  if (isToday(date)) return lang === 'fr' ? "Aujourd'hui" : 'Today';
  if (isTomorrow(date)) return lang === 'fr' ? 'Demain' : 'Tomorrow';
  return capitaliseFirstLetter(
    format(date, lang === 'fr' ? 'cccc, d MMMM' : 'cccc, do MMMM', { locale }) || '',
  );
};
