import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Page from '../organisms/Page';

import Archive from '../organisms/Archive';
import ArchiveSwitch from '../molecules/ArchiveSwitch';
import ContentsWrapper from '../Layout/ContentsWrapper';
import { PaddedRow, SearchInput } from './hosts/styles';
import { Row } from '../Layout/Flex';
import CrossSvg from '../icons/Cross';
import SearchSvg from '../icons/Search';
import { useDeviceStore } from '../../store';
import { useTheme } from 'styled-components';
import { useKey } from 'react-use';

const ArchivePage = pageData => {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef();
  const isMobile = useDeviceStore(store => store.isMobile);
  const theme = useTheme();

  useKey([], () => {
    inputRef.current.focus();
  });

  const {
    strapiArchivePage: { page_meta },
  } = useStaticQuery(
    graphql`
      query ArchivePageQuery {
        strapiArchivePage {
          page_meta {
            page_title_fr
            page_title_en
          }
        }
      }
    `,
  );

  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <Page title={{ en: page_meta.page_title_en, fr: page_meta.page_title_fr }} data={pageData}>
      <ContentsWrapper
        css={`
          position: relative;
          padding-bottom: 64px;
        `}
        $paddingTop="0"
      >
        <PaddedRow justify="space-between" align="center">
          <ArchiveSwitch />
          <Row justify="flex-end" align="center">
            <SearchInput
              onChange={e => setSearchQuery(e.target.value)}
              valueLength={searchQuery.length || 1}
              ref={inputRef}
              value={searchQuery}
            />
            {searchQuery ? (
              <CrossSvg
                size={isMobile ? 18 : 24}
                colour={theme?.foreground}
                weight="light"
                data-hoverable
                onClick={clearSearch}
              />
            ) : (
              <SearchSvg
                size={isMobile ? 18 : 24}
                colour={theme?.foreground}
                data-hoverable
                onClick={() => inputRef.current.focus()}
              />
            )}
          </Row>
        </PaddedRow>
        <Archive searchQuery={searchQuery} />
      </ContentsWrapper>
    </Page>
  );
};

export default ArchivePage;
