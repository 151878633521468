import React from 'react';
import { bool } from 'prop-types';
import Link from '../../atoms/Link';
import { H3 } from '../../atoms/Text';
import { StyledRow as Row } from './styles';

const ArchiveSwitch = ({ isHosts }) => (
  <Row>
    <Link
      disabled={!isHosts}
      css={`
        margin-right: 32px;
        opacity: ${isHosts ? 0.5 : 1};
      `}
      to="/archive"
    >
      <H3>Shows</H3>
    </Link>
    <Link
      disabled={isHosts}
      to="/hosts"
      css={`
        opacity: ${isHosts ? 1 : 0.5};
      `}
    >
      <H3>Hosts</H3>
    </Link>
  </Row>
);

ArchiveSwitch.propTypes = {
  isHosts: bool,
};

export default ArchiveSwitch;
