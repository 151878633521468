/* eslint-disable react/jsx-curly-newline */
import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { format } from 'date-fns';

import { useLangStore } from '../../../store';
import Show from '../../molecules/Show';
import { H3, P, Span } from '../../atoms/Text';

import { useTranslation } from '../../../hooks/TranslationHooks';

import { getArchiveFromShowData, formatDay } from './helpers';
import { DateHeader, DayList, NoResultWrapper, Fade } from './styles';
import { slugify } from '../../../helpers/string';
import { NoResult } from '../HostsGrid/styles';
import { textSizes } from '../../../assets/theme';
import { useKeyPress } from 'react-use';

const Archive = ({ searchQuery }) => {
  const currentLang = useLangStore(store => store.currentLang);
  const [openItemId, setOpenItemId] = useState('');
  const noResultString = useTranslation({ key: 'hostSearchNoResult' })[currentLang];

  const data = useStaticQuery(graphql`
    query {
      allStrapiUpcomingShow(sort: { fields: [broadcast_start_time], order: ASC }) {
        nodes {
          fields {
            slug
          }
          id
          title
          broadcast_start_time
          broadcast_end_time
          description_en
          description_fr
          active
          mixcloud_slug
          image {
            publicURL
            ext
          }
          hosts {
            Name
            image {
              publicURL
              ext
            }
          }
          tags {
            name_en
            name_fr
          }
          languages {
            name
          }
        }
      }
    }
  `);
  const archiveShows = getArchiveFromShowData(data, searchQuery);

  return Object.keys(archiveShows).length !== 0 ? (
    <>
      {Object.keys(archiveShows || {})
        .sort((a, b) => new Date(b) - new Date(a))
        .map((day, dayIndex) => {
          return (
            <DayList key={day} isLast={dayIndex + 1 === Object.keys(archiveShows || {}).length}>
              <DateHeader>
                <H3>{formatDay(day, currentLang)}</H3>
              </DateHeader>
              {archiveShows[day].map(
                (
                  {
                    title,
                    hosts,
                    broadcast_start_time: broadcastStartTime,
                    broadcast_end_time: broadcastEndTime,
                    description_en: descriptionEn,
                    description_fr: descriptionFr,
                    mixcloud_slug: mixcloudSlug,
                    image,
                    id,
                    tags,
                    languages,
                    fields,
                  },
                  i,
                ) => {
                  const startDay = format(new Date(broadcastStartTime), 'yyyy/M/d');
                  const datestring = `${startDay} ${broadcastEndTime?.substring(0, 5)}`;
                  const broadcastEnd = new Date(datestring);
                  const handleItemClick = () => {
                    if (openItemId === id) {
                      setOpenItemId(0);
                    } else {
                      setOpenItemId(id);
                    }
                  };

                  return (
                    <Show
                      // id={idSlug}
                      key={id}
                      name={title}
                      hosts={hosts}
                      startTime={new Date(broadcastStartTime)}
                      endTime={broadcastEnd}
                      image={image?.publicURL}
                      isLast={i + 1 === archiveShows[day].length}
                      onClick={handleItemClick}
                      isOpen={id === openItemId}
                      mixcloudSlug={mixcloudSlug}
                      description={{
                        en: descriptionEn,
                        fr: descriptionFr,
                      }}
                      isArchive
                      tags={tags}
                      languages={languages}
                      slug={fields.slug}
                    />
                  );
                },
              )}
            </DayList>
          );
        })}
      <Fade />
    </>
  ) : (
    <NoResult opacity={0.5} size={textSizes.xl} align="center">
      {noResultString} <Span italic>{`"${searchQuery}"`}</Span>
    </NoResult>
  );
};

Archive.propTypes = {};

export default Archive;
