import styled from 'styled-components';
import { mediaQuery, spacing } from '../../../assets/theme';
import { Label } from '../../atoms/Text';

export const Grid = styled.div`
  display: grid;
  grid-column-gap: ${spacing[4]}px;
  grid-row-gap: ${spacing[4]}px;
  margin-bottom: ${spacing[6]}px;
  padding: 0 2px;
  ${mediaQuery.greaterThan('sm')`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}
  ${mediaQuery.greaterThan('md')`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
  ${mediaQuery.greaterThan('lg')`
    grid-template-columns: repeat(5, minmax(0, 1fr));
  `}
  ${mediaQuery.greaterThan('xl')`
    grid-template-columns: repeat(6, minmax(0, 1fr));
  `}
`;

export const NoResult = styled(Label)`
  width: 100%;
  display: block;
  padding: 20vh 0;
`;
